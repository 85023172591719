.homeContainer{
    position:relative;
    height: 100vh;
    width: 100%;
    margin-top:100vh;
    background-color: #b691ff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.homeMyName{
    margin-top: 1vh;
    font-size: 5em;
    margin-bottom: 5vh;
    text-align: center;
}

.homeCenterLogoContainer{
    display: flex;
    margin-top: -5vh;
    align-items: center;
    flex-direction: column;
}

.homeCenterLogo{
    border-radius: 50%;
    width: 50vh;
    max-width: 400px;
    object-fit: cover;
    padding: 10px;
}