.communityContainer{
    min-height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #b691ff;
    position: relative;
}

.communityTitle{
    font-size: 3em;
    margin-bottom: 2vh;
    text-align: center;
}
@media screen and (min-width: 700px) {
    .communityTitle {
        font-size: 4em;
    }
}

.communityLinksContainer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    max-width: 1000px;
}

.communityLinks{
    width: 40%;
}

.communityNameAndImage{
    width: 100%
}

.communityImage{
    width: 100%;
    border-radius: 5%;
    cursor: pointer;
    border: 4px solid #b691ff;
}


.communityImage:hover{
    -webkit-animation: fadeIn 0.5s forwards;
    -moz-animation: fadeIn 0.5s forwards;
    -o-animation: fadeIn 0.5s forwards;
    animation: fadeIn 0.5s forwards;
 }
 
 
 @keyframes fadeIn {
    100% {
        display: block;
        border: 4px solid #FFE8FF;
    }
 }