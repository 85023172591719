.aboutContainer{
    width: 100%;
    background-color: #FFE8FF;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-bottom: 20px;
}

.aboutTitle{
    font-size: 3em;
    margin-bottom: 2vh;
    text-align: center;
}

@media screen and (min-width: 700px) {
    .aboutTitle {
        font-size: 4em;
    }
}

.aboutParagraphContainer{
    width: 80%;

}

.linksContainer{
    display: flex;
    justify-content: center;
}

.aboutLinks{
    text-decoration: inherit;
    color: inherit;
    margin: 10px;
}

.fa-icons{
    font-size: 30px;
}
