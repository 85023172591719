

.bg{
    width: 100%;
    height: 100%;
    position:fixed;
    background-color: #FFE8FF;
    top:0;
    z-index:-99;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
 }

 .carouselTitle{
   background-color: #FFE8FF
 }
 .carouselImg{
   width: 100%;
   border-radius: 10%;
 }

.prevImage{
   position: fixed;
   left: 0;
   height: 70%;
   opacity: .1;
   background-color: #FFE8FF;
   border: 0;
}
.prevImage:hover{
   -webkit-animation: fadeIn 0.5s forwards;
   -moz-animation: fadeIn 0.5s forwards;
   -o-animation: fadeIn 0.5s forwards;
   animation: fadeIn 0.5s forwards;
}


.nextImage{
   position: fixed;
   right: 0;
   height: 70%;
   opacity: .1;
   background-color: #FFE8FF;
   border: 0;
}

.nextImage:hover{
   -webkit-animation: fadeIn 0.5s forwards;
   -moz-animation: fadeIn 0.5s forwards;
   -o-animation: fadeIn 0.5s forwards;
   animation: fadeIn 0.5s forwards;
}




@keyframes fadeIn {
   100% {
       display: block;
       opacity: .5;
   }
}