.ledView{
    background-color: #FFE8FF;
    height: 100vh;
    font-family: 'Patua One', cursive;
    font-size: 20px;
    display: block;
    
}

.signOutButton{
    width: 100px;
}

.createDeviceButton{
    width: 100px;
}

.updateDeviceButton{
    width: 140px;
}

.ledNavButtons{
    border: 1px solid #FFE8FF;
    height: 50px;
    background-color: #b691ff;
    cursor: pointer;
    font-size: 15px;
}

.buttonContainers{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.deviceButtons{
    border: 1px solid #b691ff;
    height: 10vh;
    max-height: 70px;
    background-color: #F9F3E6;
    width: 25%;
    min-width: 100px;
    max-width: 300px;
    margin: 10px;
    text-align: center;
    font-size: 4vw;
    cursor: pointer;
    font-family: 'Patua One', cursive;
}

.sceneSelectedButton{
    animation: fadeInOut .5s linear forwards;
}
 
@keyframes fadeInOut {
    0% {
        background-color: #F9F3E6;
    }
    50% {
        background-color: #b691ff;
    }
    100% {
        background-color: #F9F3E6;
    }
  }

@media screen and (min-width: 630px) {
    .deviceButtons {
       font-size: 24px;
    }
}

.deviceSelected{
    background-color: #b691ff;
}

.ledNav{
    display: flex;
    justify-content: space-between;
}

.createUpdateContainer{
    display: flex;
    justify-content: flex-end;
}
.createDeviceForm{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.updateDeviceForm{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.updateDeviceFormItem{
    display: flex;
    justify-content: flex-end;
}

.updateInputs{
    display: flex;
}

.updateDeviceId{
    width: 100px;
}

.centerText{
    text-align: center;
}

.clickable{
    cursor: pointer;
}

.updateButton{
    width: 100%;
    border: 1px solid #FFE8FF;
    background-color: #b691ff;
    cursor: pointer;
}

.submitButton{
    width: 246px;
    border: 1px solid #FFE8FF;
    background-color: #b691ff;
    cursor: pointer;
}

.rangeBars{
    width: 80% !important; 
    height: 30px; 
}
// .createUpdateContainer{
//     display: flex;
//     flex-direction: column;
// }
